export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "us-east-1",
    BUCKET: "saleobration-app-uploads"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://zo8mngz3k7.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_8lZHROyNo",
    APP_CLIENT_ID: "340uvv9siggvqk9mifi4830b4t",
    IDENTITY_POOL_ID: "us-east-1:fa592cf9-8786-4a0f-bd6b-4b2d922d5fb0"
  }
};
